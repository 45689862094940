<template>
  <div class="games">

    <div class="content">
      <div class="title">Історії Тева</div>

      <div class="item-wrapper">
        <div class="item" v-for="(key, value ) in games">

          <picture v-if="!key.disable">
            <source
                :srcset="require(`@/assets/img/game-list/${key.mob}`)"
                media="(max-width: 560px)">
            <img :src="require(`@/assets/img/game-list/${key.img}`)" alt="">
          </picture>

          <picture v-else>
            <source
                srcset="../assets/img/game-list/dis-mob.png"
                media="(max-width: 560px)">
            <img src="../assets/img/game-list/dis.png" alt="">
          </picture>

          <div class="count">{{ value + 1 }}</div>
          <router-link v-if="!key.disable" class="hover_type6 btn" :to="`${key.link}`"><p>Перейти</p></router-link>
          <p v-else class="disable-text">Недоступно</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "games",
  data: () => ({
    trigger25: true,
    trigger50: true,
    trigger75: true,
    trigger90: true,
    games: [
      {
        link: "/games/desloratadine",
        disable: false,
        img: "5.png",
        mob: "5-mob.png",
      },
      {
        link: "/games/asa",
        disable: false,
        img: "10.png",
        mob: "10-mob.png",
      },
      {
        link: "/games/stoptusin",
        disable: false,
        img: "8.png",
        mob: "8-mob.png",
      },
      {
        link: "/games/acetylcysteine",
        disable: false,
        img: "1.png",
        mob: "1-mob.png"
      },
      {
        link: "/games/budesonid",
        disable: false,
        img: "2.png",
        mob: "2-mob.png",
      },
      {
        link: "/games/atorvastatin",
        disable: false,
        img: "3.png",
        mob: "3-mob.png"
      },
      {
        link: "/games/clopidogrel",
        disable: false,
        img: "4.png",
        mob: "4-mob.png",
      },

      {
        link: "/games/omeprazol",
        disable: false,
        img: "6.png",
        mob: "6-mob.png",
      },
      {
        link: "/games/ramipril",
        disable: false,
        img: "7.png",
        mob: "7-mob.png",
      },

      {
        link: "/games/valsartan",
        disable: false,
        img: "9.png",
        mob: "9-mob.png",
      },

    ]
  }),
}
</script>

<style scoped lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.games {
  position: relative;

  min-height: calc(100vh);
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_content));

  max-width: 1270px;
  width: 100%;
  margin: 0 auto;

  padding: 40px 20px;

  @media screen and (max-width: 768px) {
    padding: 29px 20px;

  }

  .content {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto 0 auto;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
    }

    .title {
      font-family: $B;
      font-size: 48px;
      color: $grey;
      margin-bottom: 20px;
      @media screen and (max-width: 560px) {
        font-size: 24px;
        line-height: 33px;
      }
    }

    .item-wrapper {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -10px;

      .item {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        max-width: calc(25% - 20px);
        width: 100%;
        margin: 10px;
        position: relative;

        @media screen and (max-width: 1024px) {
          max-width: calc((100% / 3) - 20px);
        }
        @media screen and (max-width: 820px) {
          max-width: calc((100% / 2) - 20px);
        }
        @media screen and (max-width: 560px) {
          max-width: calc((100% / 1) - 20px);
        }

        .count {
          position: absolute;
          z-index: 2;
          left: 20px;
          top: 20px;
          background: white;
          border-radius: 50%;
          width: 44px;
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 16px;
          line-height: 22px;

          font-family: $EB;
          color: $green;

        }

        .disable-text {
          font-size: 16px;
          line-height: 22px;
          color: $grey;
          font-family: $M;
          display: flex;
          align-items: center;
          justify-content: center;

          padding: 14px 33px;
          margin: 10px auto;
          max-width: calc(100% - 60px);
          width: 100%;
          text-decoration: none;

        }

        .btn {
          padding: 14px 33px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px auto;
          max-width: calc(100% - 66px);
          width: 100%;
          color: $green;
          font-family: $EB;
          font-size: 16px;
          text-decoration: none;
          border: 2px solid $green;
          border-radius: 35px;

          background: white;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }
}
</style>